<template>
  <div>
    Individual Analytics
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachAnalyticsIndividual"
}
</script>
